<template>
  <div>
    <FleetAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :primary-key="ae.primaryKey"
      :raw-data="ae.rawData"
      :busy="ae.busy"
      :operator-app-enabled="hasOperatorApp"
      :org-email-settings="orgEmailSettings"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />

    <section v-if="!isLoaded" class="mt-2 h-96 bg-white shadow vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </section>

    <section v-if="isLoaded">
      <add-edit-fleet :is_edit="is_edit" />
      <div
        class="grid lg:grid-cols-2 lg:space-x-2 md:space-x-0 md:space-y-2 md:grid-cols-1"
      >
        <info-grid
          grid-class="grid-cols-1"
          :title="
            $t(
              'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.gridTitle'
            )
          "
          :data="getProfile()"
          @edit="handleEditing({ step: 1 })"
        />
        <div class="grid grid-cols-1">
          <content-section class="py-6 rounded-b-none rounded-t-lg shadow-none">
            <div class="flex justify-between items-center">
              <div class="text-xl font-semibold uppercase text-oDark">
                {{
                  $t(
                    'components.fleetDetailsManagement.diveIntoTab.profile.grid.fleetVisibilityInfo.gridTitle'
                  )
                }}
              </div>
              <oto-edit-button @click="handleEditing({ step: 2 })" />
            </div>
            <dashed-blue-border class="my-8 -mx-3">
              <div class="fvc-code">
                <div class="w-full text-center">
                  <t-input
                    class="w-full text-center"
                    v-model="code"
                    ref="code"
                    variant="untraceable"
                    readonly="readonly"
                  />
                  <div class="font-normal text-14px text-oGray">
                    {{
                      $t(
                        'components.fleetDetailsManagement.diveIntoTab.profile.grid.fleetVisibilityInfo.infoTitle.fleetVisibilityPrivateCode'
                      )
                    }}
                  </div>
                </div>
                <div
                  class="float-right mr-3 cursor-pointer text-oGray"
                  @click="copyToClipBoard()"
                >
                  <i class="far fa-copy"></i>
                </div>
              </div>
            </dashed-blue-border>
            <div class="mt-2 mb-6 text-xs text-center text-oLightGray">
              {{
                $t(
                  'components.fleetDetailsManagement.diveIntoTab.profile.grid.fleetVisibilityInfo.infoTitle.lastUpdatedTime'
                )
              }}
              :
              {{ profile.last_refreshed_at | friendlyDateTime }}
            </div>
          </content-section>
          <info-grid
            grid-class="grid-cols-1"
            :title="
              $t(
                'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.gridTitle'
              )
            "
            :data="getVehicle()"
            @edit="handleEditing({ step: 2 })"
          />
        </div>
      </div>
      <div
        class="grid mt-2 space-y-0 lg:grid-cols-2 md:grid-cols-1 lg:space-x-2 md:space-x-0 md:space-y-2"
      >
        <info-grid
          grid-class="grid-cols-1"
          :title="
            $t(
              'components.fleetDetailsManagement.diveIntoTab.profile.grid.companyDetailsInfo.gridTitle'
            )
          "
          :edit-button="false"
          :data="getCompany()"
          @edit="handleEditing({ step: 1 })"
        />
        <div>
          <info-grid
            grid-class="grid-cols-1"
            :title="
              $t(
                'components.fleetDetailsManagement.diveIntoTab.profile.grid.mailConfigurationInfo.gridTitle'
              )
            "
            :edit-button="false"
            :data="getEmail()"
            @edit="handleEditing({ step: 2 })"
          />
        </div>
      </div>
      <div class="grid grid-cols-1">
        <div class="mt-4 w-full bg-white rounded">
          <div class="lg:w-2/4 sm:w-full">
            <info-grid
              :title="
                $t(
                  'components.fleetDetailsManagement.diveIntoTab.profile.grid.operatorAppInfo.gridTitle'
                )
              "
              class="shadow-none"
              grid-class="grid-cols-1"
              :data="getOperatorApp()"
              @edit="handleEditing({ type: 'operator' })"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { FleetConfig } from '@/config/FleetConfig'
import { OperatorSettingsConfig } from '@/config/SettingsConfig'
import dayjs from 'dayjs'
import InfoGrid from '@/components/layout/InfoGrid'
import ContentSection from '@/components/layout/ContentSection'
import OtoEditButton from '@/components/ui/OtoEditButton'
import DashedBlueBorder from '@/components/ui/DashedBlueBorder'
import AddEditFleet from '@/views/fleet/AddEditFleet.vue'
import { mapGetters } from 'vuex'

import FleetAddEdit from '../FleetAddEdit.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewFleetProfile',
  components: {
    InfoGrid,
    ContentSection,
    OtoEditButton,
    DashedBlueBorder,
    AddEditFleet,
    FleetAddEdit,
  },
  data() {
    return {
      symbol: '$',
      isLoaded: false,
      fleet: {},
      code: '121',
      fallbackText: '--',
      profile: {
        address: '',
        city: {
          id: '',
          name: '',
        },
        country: {
          id: '',
          name: '',
        },
        created_at: '',
        default_financial_account: '',
        email_address: '',
        email_enabled: '',
        email_encryption: '',
        email_host: '',
        email_port: '',
        geofence: '',
        id: '',
        is_parking_required: '',
        last_refreshed_at: '',
        lock_min_battery: '',
        max_speed_limit: '',
        name: '',
        off_ride_location_update_frequency: '',
        on_ride_location_update_frequency: '',
        organization: '',
        private_code: '',
        private_code_refresh_time: '',
        registration_no: '',
        reservation_time: '',
        tax_id: '',
        updated_at: '',
        use_company_details: '',
        visibility: '',
        warehouse_lat: '',
        warehouse_lon: '',
        warehouse_name: '',
        zip_code: '',
        is_edit: false,
      },
      is_edit: false,
      operator: {
        charging: {},
        rebalancing: {},
      },

      // add-edit
      orgEmailSettings: {},
      ae: {
        // EdgeStack component
        esId: 'fleet-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    id() {
      return this.$route.params.id
    },
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    hasOperatorApp() {
      // console.log('hasOperatorApp?', this.$org.subscription.operator_app_enabled)
      return this.$org.subscription.operator_app_enabled
    },
  },
  async created() {
    const reqFleet = this.$http.get(FleetConfig.api.single(this.id))
    const reqOperatorCharging = this.$http.get(
      OperatorSettingsConfig.api.charging.index(this.orgId)
    )
    const reqOperatorRebalancing = this.$http.get(
      OperatorSettingsConfig.api.rebalancing.index(this.orgId)
    )
    const reqOrgEmailSettings = this.$http.get(
      useEndpoints.settings.email.index(this.orgId)
    )

    await this.$http
      // todo: Improvement - fetchingError: false -> true
      // todo: Send req based on subscription
      .all([
        reqFleet,
        reqOperatorCharging,
        reqOperatorRebalancing,
        reqOrgEmailSettings,
      ])
      .then((res) => {
        console.log('fleet-profile', res)
        this.profile = {
          ...res[0].data,
        }
        this.code = res[0].data.private_code
        this.operator.charging = res[1].data
        this.operator.rebalancing = res[2].data

        const orgEmailSettings = res[3].data
        // replace null as empty string
        const formatted = {}
        for (const k in orgEmailSettings) {
          formatted[k] =
            orgEmailSettings[k] === 'null' ? '' : orgEmailSettings[k]
        }
        this.orgEmailSettings = formatted

        this.isLoaded = true
        // console.log(this.profile, this.operator);
      })
      .catch((err) => {
        console.log('fleet-profile-err', err, err.response)
        this.$notify({
          group: 'bottomRight',
          type: 'error',
          title: 'HTTP Error',
          text: 'Failed to load org email settings!',
        })
      })
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
  },
  methods: {
    getProfile() {
      let profile = []
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleetName'
        ),
        value: this.profile.name,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleetID'
        ),
        value: this.profile.id,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleetOrganization'
        ),
        value: this.profile.organization.name || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleetLocation'
        ),
        value: this.profile.warehouse_name || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleetCountry'
        ),
        value: this.profile.country.name || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.billingPlan'
        ),
        value: this.profile.billing_plan || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.alertEmailAddress'
        ),
        value: this.profile.email_address || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.operationalVehicle'
        ),
        value: this.profile.operational_vehicle || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.createdAt'
        ),
        value: dayjs(this.profile.created_at).format('DD MMM, YYYY'),
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleetVisibility'
        ),
        value: this.profile.fleet_visibility || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.domain'
        ),
        value: this.profile.domain || this.fallbackText,
      })
      profile.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.port'
        ),
        value: this.profile.port || this.fallbackText,
      })
      return profile
    },
    getVehicle() {
      let data = []
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.infoTitle.locationUpdateFrequencyOnRide'
        ),
        value:
          this.profile.on_ride_location_update_frequency + 's' ||
          this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.infoTitle.locationUpdateFrequencyOffRide'
        ),
        value:
          this.profile.off_ride_location_update_frequency + 's' ||
          this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.infoTitle.vehicleReservationTime'
        ),
        value: this.profile.reservation_time + 'm' || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.infoTitle.maxSpeedLimit'
        ),
        value: this.profile.max_speed_limit,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.infoTitle.vehicleSpeedMode'
        ),
        value: this.profile.vehicle_speed_mode || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.infoTitle.isParkingRequired'
        ),
        value: this.profile.is_parking_required ? 'Yes' : 'No',
      })
      return data
    },
    getCompany() {
      let data = []
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.companyDetailsInfo.infoTitle.address'
        ),
        value: this.profile.address || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.companyDetailsInfo.infoTitle.city'
        ),
        value: this.profile.city.name || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.companyDetailsInfo.infoTitle.postalZip'
        ),
        value: this.profile.zip_code || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.companyDetailsInfo.infoTitle.taxID'
        ),
        value: this.profile.tax_id || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.companyDetailsInfo.infoTitle.registrationNO'
        ),
        value: this.profile.registration_no || this.fallbackText,
      })
      return data
    },
    getEmail() {
      let data = []
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.mailConfigurationInfo.infoTitle.emailAddress'
        ),
        value: this.profile.email_address || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.mailConfigurationInfo.infoTitle.host'
        ),
        value: this.profile.email_host || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.mailConfigurationInfo.infoTitle.port'
        ),
        value: this.profile.email_port || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.mailConfigurationInfo.infoTitle.password'
        ),
        value: '*********',
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.mailConfigurationInfo.infoTitle.encryption'
        ),
        value: this.profile.email_encryption || this.fallbackText,
      })
      return data
    },
    copyToClipBoard() {
      this.$refs.code.select()
      this.$refs.code.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Code Copied',
          text:
            'Fleet visibility private code has been copied to your clipboard',
        },
        2000
      )
    },
    async handleEditing({ type = null, step = 1 } = {}) {
      if (type === 'operator') {
        this.$router.push({
          name: 'OperatorSettings',
        })
        return
      }

      await this.onOpenES({ primaryKey: this.id, step })
      // var item = {
      //   id: this.id,
      //   step: type,
      // }
      // EventBus.$emit(FleetConfig.events.editingData, item)
      // dispatchEvent(new Event(FleetConfig.events.sorToggle))
      // this.is_edit = true
    },
    getOperatorApp() {
      let data = []
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.operatorAppInfo.infoTitle.chargingEarningRate'
        ),
        value: this.symbol + this.operator.charging.rate || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.operatorAppInfo.infoTitle.chargingPointRate'
        ),
        value: this.operator.charging.point || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.operatorAppInfo.infoTitle.rebalancingEarningRate'
        ),
        value:
          this.symbol + this.operator.rebalancing.rate || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.fleetDetailsManagement.diveIntoTab.profile.grid.operatorAppInfo.infoTitle.rebalancingPointRate'
        ),
        value: this.operator.rebalancing.point || this.fallbackText,
      })
      return data
    },

    async onOpenES({ primaryKey, step }) {
      if (typeof primaryKey === 'string') {
        this.ae.busy = true
        this.$edgeStack.open(this.ae.esId)

        const fleetReq = this.$http.get(useEndpoints.fleet.details(primaryKey))

        const requests = [fleetReq]
        if (this.hasOperatorApp) {
          const operatorChargingReq = this.$http.get(
            useEndpoints.fleet.operator.charging(primaryKey)
          )
          const operatorRebalanceReq = this.$http.get(
            useEndpoints.fleet.operator.rebalance(primaryKey)
          )

          requests.push(operatorChargingReq, operatorRebalanceReq)
        }

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              // console.log(fleetRes, chargingRes, rebalanceRes)

              let data = {}
              if (this.hasOperatorApp) {
                const [fleetRes, chargingRes, rebalanceRes] = responses
                data = {
                  ...fleetRes.data,
                  charging_task: chargingRes.data,
                  rebalance_task: rebalanceRes.data,
                }
              } else {
                const [fleetRes] = responses
                data = {
                  ...fleetRes.data,
                  charging_task: {
                    point: '',
                    rate: '',
                  },
                  rebalance_task: {
                    point: '',
                    rate: '',
                  },
                }
              }

              // console.log(data)

              this.ae.rawData = data
              this.ae.primaryKey = primaryKey
              this.ae.stepperMode = 'free'

              this.ae.busy = false

              // console.log(this.ae.rawData)
              // ** open a random step (keeping it as a reference)
              // function randomNumber(min, max) {
              //   const r = Math.random() * (max - min) + min
              //   return Math.floor(r)
              // }
              this.$edgeStack.emitter.on(
                this.$edgeStack.getEventName('opened', this.ae.esId),
                () => {
                  console.log('step', step)
                  this.$xStepper.navigate(this.ae.esId).to(step)
                }
              )
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.ae.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.ae.stepperMode = 'strict'

      this.ae.busy = false
      this.ae.rawData = null
      this.ae.primaryKey = null

      this.$edgeStack.open(this.ae.esId)
      // this.$xStepper.navigate(this.ae.esId).to(4)
    },
  },
}
</script>
<style lang="scss">
.fvc-code {
  display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  background: #f6f9ff;
  height: 86px;
  width: 100%;
}
</style>
