var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FleetAddEdit',{attrs:{"es-id":_vm.ae.esId,"stepper-mode":_vm.ae.stepperMode,"stepper-step":_vm.ae.stepperStep,"primary-key":_vm.ae.primaryKey,"raw-data":_vm.ae.rawData,"busy":_vm.ae.busy,"operator-app-enabled":_vm.hasOperatorApp,"org-email-settings":_vm.orgEmailSettings},on:{"refresh":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}}),(!_vm.isLoaded)?_c('section',{staticClass:"mt-2 h-96 bg-white shadow vld-parent"},[_c('loading',{attrs:{"active":!_vm.isLoaded,"is-full-page":false}})],1):_vm._e(),(_vm.isLoaded)?_c('section',[_c('add-edit-fleet',{attrs:{"is_edit":_vm.is_edit}}),_c('div',{staticClass:"grid lg:grid-cols-2 lg:space-x-2 md:space-x-0 md:space-y-2 md:grid-cols-1"},[_c('info-grid',{attrs:{"grid-class":"grid-cols-1","title":_vm.$t(
            'components.fleetDetailsManagement.diveIntoTab.profile.grid.basicInfo.gridTitle'
          ),"data":_vm.getProfile()},on:{"edit":function($event){return _vm.handleEditing({ step: 1 })}}}),_c('div',{staticClass:"grid grid-cols-1"},[_c('content-section',{staticClass:"py-6 rounded-b-none rounded-t-lg shadow-none"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"text-xl font-semibold uppercase text-oDark"},[_vm._v(" "+_vm._s(_vm.$t( 'components.fleetDetailsManagement.diveIntoTab.profile.grid.fleetVisibilityInfo.gridTitle' ))+" ")]),_c('oto-edit-button',{on:{"click":function($event){return _vm.handleEditing({ step: 2 })}}})],1),_c('dashed-blue-border',{staticClass:"my-8 -mx-3"},[_c('div',{staticClass:"fvc-code"},[_c('div',{staticClass:"w-full text-center"},[_c('t-input',{ref:"code",staticClass:"w-full text-center",attrs:{"variant":"untraceable","readonly":"readonly"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{staticClass:"font-normal text-14px text-oGray"},[_vm._v(" "+_vm._s(_vm.$t( 'components.fleetDetailsManagement.diveIntoTab.profile.grid.fleetVisibilityInfo.infoTitle.fleetVisibilityPrivateCode' ))+" ")])],1),_c('div',{staticClass:"float-right mr-3 cursor-pointer text-oGray",on:{"click":function($event){return _vm.copyToClipBoard()}}},[_c('i',{staticClass:"far fa-copy"})])])]),_c('div',{staticClass:"mt-2 mb-6 text-xs text-center text-oLightGray"},[_vm._v(" "+_vm._s(_vm.$t( 'components.fleetDetailsManagement.diveIntoTab.profile.grid.fleetVisibilityInfo.infoTitle.lastUpdatedTime' ))+" : "+_vm._s(_vm._f("friendlyDateTime")(_vm.profile.last_refreshed_at))+" ")])],1),_c('info-grid',{attrs:{"grid-class":"grid-cols-1","title":_vm.$t(
              'components.fleetDetailsManagement.diveIntoTab.profile.grid.vehicleSettingsInfo.gridTitle'
            ),"data":_vm.getVehicle()},on:{"edit":function($event){return _vm.handleEditing({ step: 2 })}}})],1)],1),_c('div',{staticClass:"grid mt-2 space-y-0 lg:grid-cols-2 md:grid-cols-1 lg:space-x-2 md:space-x-0 md:space-y-2"},[_c('info-grid',{attrs:{"grid-class":"grid-cols-1","title":_vm.$t(
            'components.fleetDetailsManagement.diveIntoTab.profile.grid.companyDetailsInfo.gridTitle'
          ),"edit-button":false,"data":_vm.getCompany()},on:{"edit":function($event){return _vm.handleEditing({ step: 1 })}}}),_c('div',[_c('info-grid',{attrs:{"grid-class":"grid-cols-1","title":_vm.$t(
              'components.fleetDetailsManagement.diveIntoTab.profile.grid.mailConfigurationInfo.gridTitle'
            ),"edit-button":false,"data":_vm.getEmail()},on:{"edit":function($event){return _vm.handleEditing({ step: 2 })}}})],1)],1),_c('div',{staticClass:"grid grid-cols-1"},[_c('div',{staticClass:"mt-4 w-full bg-white rounded"},[_c('div',{staticClass:"lg:w-2/4 sm:w-full"},[_c('info-grid',{staticClass:"shadow-none",attrs:{"title":_vm.$t(
                'components.fleetDetailsManagement.diveIntoTab.profile.grid.operatorAppInfo.gridTitle'
              ),"grid-class":"grid-cols-1","data":_vm.getOperatorApp()},on:{"edit":function($event){return _vm.handleEditing({ type: 'operator' })}}})],1)])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }