<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <!-- payment -->
      <section>
        <!-- row-1 -->
        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.useOrgPaymentGateway"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.useDefaultPaymentGatewayCheckbox'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.useDefaultPaymentGatewayCheckbox'
              )
            "
            wrapped
          />
        </div>
        <div>
          <AppInput
            v-model="form.payment.payment_gateway"
            type="richselect"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.paymentGateway'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.paymentGateway'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.paymentGateway'
              )
            "
            :placeholder="
              $t(
                'components.fleetManagement.addEdit.steps.payment.placeHolder.paymentGateway'
              )
            "
            rules="required"
            text-attribute="name"
            value-attribute="id"
            :options="paymentGateways"
            :disabled="form.useOrgPaymentGateway"
            hide-search-box
          />
        </div>
        <!-- /row-1 -->

        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.payment.sandbox_mode"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableLiveModeCheckbox'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableLiveModeCheckbox'
              )
            "
            :disabled="form.useOrgPaymentGateway"
            wrapped
          />
        </div>

        <div v-if="isItPayoneMethod">
          <!-- row-2 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.payone_mid"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneMID'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneMID'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneMID'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />

            <AppInput
              v-model="form.payment.payone_portalid"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payonePortalID'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payonePortalID'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payonePortalID'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />
          </div>
          <!-- /row-2 -->

          <!-- row-3 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.payone_aid"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneAID'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneAID'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneAID'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />

            <AppInput
              v-model="form.payment.payone_key"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneKey'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.payoneKey'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />
          </div>
          <!-- /row-3 -->
        </div>

        <div v-else-if="isItKKiaPayMethod">
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.kkiapay_sandbox_public_key"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxPublicKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxPublicKey'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxPublicKey'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />
            <AppInput
              v-model="form.payment.kkiapay_live_public_key"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLivePublicKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLivePublicKey'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLivePublicKey'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.kkiapay_sandbox_private_key"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxPrivateKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxPrivateKey'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxPrivateKey'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />

            <AppInput
              v-model="form.payment.kkiapay_live_private_key"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLivePrivateKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLivePrivateKey'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLivePrivateKey'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.kkiapay_sandbox_secret"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxSecret'
                )
              "
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxSecret'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipaySandboxSecret'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />
            <AppInput
              v-model="form.payment.kkiapay_live_secret"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLiveSecret'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLiveSecret'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.kkipayLiveSecret'
                )
              "
              :disabled="form.useOrgPaymentGateway"
            />
          </div>
        </div>
        <div v-else-if="isItMyFatoorahMethod">
          <div v-if="!form.payment.sandbox_mode">
            <AppInput
              v-model="form.payment.sandbox_secret_key"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.testApiToken'
                )
              "
              rules="required"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.testApiToken'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.placeHolder.testApiToken'
                )
              "
            />
          </div>
          <div v-else>
            <AppInput
              v-model="form.payment.secret_key"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.apiToken'
                )
              "
              rules="required"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.apiToken'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.placeHolder.apiToken'
                )
              "
            />
            <AppInput
              v-model="form.payment.src_url"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.javascriptSdkUrl'
                )
              "
              rules="required"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.javascriptSdkUrl'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.placeHolder.javascriptSdkUrl'
                )
              "
            />
            <AppInput
              v-model="form.payment.api_url"
              type="password"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.apiBaseUrl'
                )
              "
              rules="required"
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.apiBaseUrl'
                )
              "
              :placeholder="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.placeHolder.apiBaseUrl'
                )
              "
            />
          </div>
        </div>

        <div v-else>
          <!-- row-2 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.sandbox_client_id"
              type="password"
              :name="`${getClientIdLabel}`"
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :infoDescription="
                `Please provide ${getClientIdLabel} for testing purpose`
              "
              :label="`${getClientIdLabel}`"
              :placeholder="`${getClientIdLabel}`"
              :disabled="form.useOrgPaymentGateway"
            />
            <AppInput
              v-model="form.payment.client_id"
              type="password"
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :name="`Live ${getClientIdLabel}`"
              :infoDescription="
                `Please provide ${getClientIdLabel} for production purpose`
              "
              :label="`Live ${getClientIdLabel}`"
              :placeholder="`Live ${getClientIdLabel}`"
              :disabled="form.useOrgPaymentGateway"
            />
          </div>
          <!-- /row-3 -->

          <!-- row-3 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.sandbox_secret_key"
              type="password"
              :name="getSecretKeyLabel"
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :infoDescription="
                `Please provide ${getSecretKeyLabel} for testing purpose`
              "
              :label="getSecretKeyLabel"
              :placeholder="getSecretKeyLabel"
              :disabled="form.useOrgPaymentGateway"
            />

            <AppInput
              v-model="form.payment.secret_key"
              type="password"
              :name="`Live ${getSecretKeyLabel}`"
              :infoDescription="
                `Please provide  ${getSecretKeyLabel}for production purpose`
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="`Live ${getSecretKeyLabel}`"
              :placeholder="`Live ${getSecretKeyLabel}`"
              :disabled="form.useOrgPaymentGateway"
            />
          </div>
          <!-- /row-3 -->

          <div v-if="isItStripeMethod">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.supportedPaymentMethodTypes'
                )
              "
              :label="
                $t(
                  'components.fleetManagement.addEdit.steps.payment.title.supportedPaymentMethodTypes'
                )
              "
              rules=""
              :options="supportedPaymentMethodTypes"
              placeholder="Select method"
              :hide-search-box="true"
              v-model="form.payment.supported_payment_method_types"
              :disabled="form.useOrgPaymentGateway"
              multiple
            />
          </div>
        </div>

        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.should_auto_refill_wallet_on_negative_balance"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableAutoRechargeFromWallet'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableAutoRechargeFromWallet'
              )
            "
            wrapped
          />
        </div>
        <div
          class="grid grid-cols-2 gap-4"
          v-if="form.should_auto_refill_wallet_on_negative_balance"
        >
          <AppInput
            v-model="form.auto_refill_amount"
            type="number"
            step="0.01"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.autoRechargeAmount'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.autoRechargeAmount'
              )
            "
            rules="required"
            placeholder="e.g. 10"
            :haveUnitText="true"
            :unitText="currencySymbol"
          />

          <AppInput
            v-model="form.is_rider_allowed_to_toggle_auto_refill_setting"
            type="richselect"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.allowRiderToggleAutoRecharge'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.allowRiderToggleAutoRecharge'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.allowRiderToggleAutoRecharge'
              )
            "
            rules="required"
            :options="getRiderEnabledAutoRefillOptions"
            :hide-search-box="true"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.topup.user_deposit"
            type="number"
            step="0.01"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.deposit'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.deposit'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.deposit'
              )
            "
            rules=""
            placeholder="e.g. 10"
            :haveUnitText="true"
            :unitText="currencySymbol"
          />

          <AppInput
            v-model="form.topup.user_balance_threshold"
            type="number"
            step="0.01"
            rules=""
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.lowBalanceThreshold'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.lowBalanceThreshold'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.lowBalanceThreshold'
              )
            "
            placeholder="e.g. 10"
            :haveUnitText="true"
            :unitText="currencySymbol"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.topup.user_topup_max_balance"
            type="number"
            step="0.01"
            rules=""
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.maxTopupAmount'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.maxTopupAmount'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.maxTopupAmount'
              )
            "
            placeholder="e.g. 400"
            :haveUnitText="true"
            :unitText="currencySymbol"
          />

          <AppInput
            v-model="form.topup.user_topup_min_balance"
            type="number"
            step="0.01"
            rules=""
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.minTopupAmount'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.minTopupAmount'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.minTopupAmount'
              )
            "
            placeholder="e.g. 10"
            :haveUnitText="true"
            :unitText="currencySymbol"
          />
        </div>
        <div>
          <AppInput
            v-model="form.topup.user_topup_daily_limit"
            type="number"
            step="0.01"
            rules=""
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.topupDailyLimit'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.topupDailyLimit'
              )
            "
            :infoDescription="
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.topupDailyLimit'
              )
            "
            placeholder="e.g. 400"
            :haveUnitText="true"
            :unitText="currencySymbol"
          />
        </div>
        <div class=" pb-4">
          <TCheckbox
            v-model="form.is_free_ride_allowed"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableFreeRideAllowed'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableFreeRideAllowed'
              )
            "
            wrapped
          />
          <p class="text-xs text-gray-500 ">
            {{
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.enableFreeRideAllowed'
              )
            }}
          </p>
        </div>
        <div class=" pb-4">
          <TCheckbox
            v-model="form.should_apply_extra_charge_for_non_local_rider"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableExtraSurchargeRechargeForTourist'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.enableExtraSurchargeRechargeForTourist'
              )
            "
            wrapped
          />
          <p class="text-xs text-gray-500 ">
            {{
              $t(
                'components.fleetManagement.addEdit.steps.payment.infoDescription.enableExtraSurchargeRechargeForTourist',
                { country: form.fleetCountry }
              )
            }}
          </p>
        </div>
        <div v-if="form.should_apply_extra_charge_for_non_local_rider">
          <AppInput
            v-model="form.extra_charge_percentage_for_non_local_rider"
            type="number"
            step="0.01"
            :name="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.provideExtraSurcharge'
              )
            "
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.payment.title.provideExtraSurcharge'
              )
            "
            rules="required"
            placeholder="e.g. 10"
            :haveUnitText="true"
            unitText="%"
          />
        </div>
      </section>

      <!-- /payment -->

      <button type="submit" ref="submitButton" class="hidden">Save</button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'
export default {
  name: 'Step3',

  components: {},

  props: {
    paymentGateways: {
      type: Array,
      required: true,
    },
    orgPaymentConfig: {
      type: Object,
      required: true,
    },
    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    // const { payment, link, domain } = getFormModel({ step: 3 })
    // console.log(payment, link, domain, 'form3')

    return {
      isLoading: false,
      haveTopupData: false,
      supportedPaymentMethodTypes: [
        { text: 'PayPal', value: 'PayPal' },
        { text: 'Card', value: 'Card' },
      ],
      currencySymbol: '',
      form: {
        payment: {
          payment_gateway: '',
          client_id: '',
          secret_key: '',
          app_key: 'apk-123',
          extra_key: 'exk-123',
          sandbox_mode: false,
          sandbox_client_id: '',
          sandbox_secret_key: '',
          sandbox_app_key: 'apk-123',
          supported_payment_method_types: [],
        },
        topup: {
          user_deposit: null,
          user_balance_threshold: null,
          user_topup_min_balance: null,
          user_topup_max_balance: null,
          user_topup_daily_limit: null,
        },
        useOrgPaymentGateway: false,
        should_auto_refill_wallet_on_negative_balance: false,
        auto_refill_amount: 0,
        is_rider_allowed_to_toggle_auto_refill_setting: false,
        is_free_ride_allowed: false,
        should_apply_extra_charge_for_non_local_rider: false,
        extra_charge_percentage_for_non_local_rider: 0,
        fleetCountry: '',
      },
      orgPaymentDetails: {
        payment_gateway: '',
        client_id: '',
        secret_key: '',
        app_key: '',
        extra_key: '',
        sandbox_mode: false,
        sandbox_client_id: '',
        sandbox_secret_key: '',
        sandbox_app_key: '',
        payone_mid: '',
        payone_portalid: '',
        payone_aid: '',
        payone_key: '',
        kkiapay_sandbox_public_key: '',
        kkiapay_sandbox_private_key: '',
        kkiapay_sandbox_secret: '',
        kkiapay_live_public_key: '',
        kkiapay_live_private_key: '',
        kkiapay_live_secret: '',
        supported_payment_method_types: ['Card'],
        src_url: '',
        api_url: '',
      },
      fleetPaymentDetails: {
        payment_gateway: '',
        client_id: '',
        secret_key: '',
        app_key: '',
        extra_key: '',
        sandbox_mode: false,
        sandbox_client_id: '',
        sandbox_secret_key: '',
        sandbox_app_key: '',
        payone_mid: '',
        payone_portalid: '',
        payone_aid: '',
        payone_key: '',
        kkiapay_sandbox_public_key: '',
        kkiapay_sandbox_private_key: '',
        kkiapay_sandbox_secret: '',
        kkiapay_live_public_key: '',
        kkiapay_live_private_key: '',
        kkiapay_live_secret: '',
        supported_payment_method_types: ['Card'],
        src_url: '',
        api_url: '',
      },
    }
  },

  computed: {
    getRiderEnabledAutoRefillOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },

    isItPayoneMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'Payone') {
        return true
      } else {
        return false
      }
    },
    isItKKiaPayMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'KKiaPay') {
        return true
      } else {
        return false
      }
    },
    isItStripeMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'Stripe') {
        return true
      } else {
        return false
      }
    },
    isItSTSPayoneMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'STS PayOne') {
        return true
      } else {
        return false
      }
    },
    isItMyFatoorahMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'MyFatoorah') {
        return true
      } else {
        return false
      }
    },
    getClientIdLabel() {
      if (this.isItStripeMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.title.privateKey'
        )
      }
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.title.merchantId'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.title.clientID'
      )
    },
    getClientIdInfoDescription() {
      if (this.isItStripeMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.infoDescription.privateKey'
        )
      }
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.infoDescription.merchantId'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.infoDescription.clientID'
      )
    },
    getLiveClientIdLabel() {
      if (this.isItStripeMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.title.livePrivateKey'
        )
      }
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.title.liveMerchantId'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.title.liveClientID'
      )
    },
    getLiveClientIdInfoDescription() {
      if (this.isItStripeMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.infoDescription.livePrivateKey'
        )
      }
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.infoDescription.liveMerchantID'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.infoDescription.liveClientID'
      )
    },
    getSecretKeyLabel() {
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.title.authToken'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.title.secretKey'
      )
    },
    getSecretKeyInfoDescription() {
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.infoDescription.authToken'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.infoDescription.secretKey'
      )
    },
    getLiveSecretKeyLabel() {
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.title.liveAuthToken'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.title.liveSecretKey'
      )
    },
    getLiveSecretKeyInfoDescription() {
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.fleetManagement.addEdit.steps.payment.infoDescription.liveAuthToken'
        )
      }
      return this.$t(
        'components.fleetManagement.addEdit.steps.payment.infoDescription.liveSecretKey'
      )
    },
  },

  watch: {
    // sync props.formData with $data.from
    'formData': {
      deep: true,
      immediate: true,
      handler(data) {
        if (data) {
          // todo: if domains are < 1 it might be in draft status
          // if paymentId is empty, it hasn't been created yet (probably in draft status)
          // links are optional fields

          const {
            payment,
            paymentId,
            orgId,
            useOrgPaymentGateway,
            currencySymbol,
            topup,
            should_auto_refill_wallet_on_negative_balance,
            auto_refill_amount,
            is_rider_allowed_to_toggle_auto_refill_setting,
            is_free_ride_allowed,
            should_apply_extra_charge_for_non_local_rider,
            extra_charge_percentage_for_non_local_rider,
            fleetCountry,
          } = data
          if (
            payment.supported_payment_method_types &&
            payment.supported_payment_method_types.length
          ) {
            payment.supported_payment_method_types = ['Card']
          }

          this.form = {
            payment,
            paymentId,
            orgId,
            useOrgPaymentGateway,
            topup,
            should_auto_refill_wallet_on_negative_balance,
            auto_refill_amount,
            is_rider_allowed_to_toggle_auto_refill_setting,
            is_free_ride_allowed,
            should_apply_extra_charge_for_non_local_rider,
            extra_charge_percentage_for_non_local_rider,
            fleetCountry,
          }

          this.fleetPaymentDetails = payment
            ? payment
            : this.fleetPaymentDetails
          this.currencySymbol = currencySymbol
          if (useOrgPaymentGateway) {
            this.form.payment = this.orgPaymentConfig
            console.log('ff3-useOrgPayment', true, this.orgPaymentConfig)
          } else {
            this.form.payment = this.fleetPaymentDetails
            console.log('ff3-useOrgPayment', false)
          }
          this.form.payment = {
            ...this.form.payment,
            sandbox_mode: !this.form.payment.sandbox_mode,
          }
          console.log(this.form, 'ff3')
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    'form': {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 3, data: this.form })
      },
    },
    'form.useOrgPaymentGateway': function(value) {
      if (value) {
        this.form.payment = {
          ...this.orgPaymentConfig,
          sandbox_mode: !this.form.payment.sandbox_mode,
        }
      } else {
        this.form.payment = {
          ...this.fleetPaymentDetails,
          sandbox_mode: !this.form.payment.sandbox_mode,
        }
      }
    },
  },

  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      /**
       * Payment
       *
       * in draft status -> the payment gateway might not been created,
       * meaning there's no paymentId, in that case, it need to be created.
       */
      const paymentId = this.form.paymentId

      const paymentUrl = paymentId
        ? useEndpoints.paymentGateway.update(paymentId)
        : useEndpoints.paymentGateway.create()

      const paymentMethod = paymentId ? 'PATCH' : 'POST'

      console.log({ paymentMethod })

      const paymentData = new FormData()
      const jsonstring_supported_payment_method_types = JSON.stringify(
        this.form.payment.supported_payment_method_types
      )
      const paymentFormData = {
        ...this.form.payment,
        sandbox_mode: !this.form.payment.sandbox_mode,
        supported_payment_method_types:
          jsonstring_supported_payment_method_types ?? JSON.stringify(['Card']),
        organization: this.form.orgId,
        fleet: this.primaryKey,
      }

      for (const k in paymentFormData) {
        paymentData.append(k, paymentFormData[k])
      }

      const topupUrl = this.isEditing
        ? useEndpoints.fleet.update(this.primaryKey)
        : useEndpoints.fleet.create()

      const topupMethod = this.isEditing ? 'PATCH' : 'POST'

      const topupData = new FormData()
      const topupFormData = {
        ...this.form.topup,
      }

      for (const k in topupFormData) {
        if (topupFormData[k]) {
          this.haveTopupData = true
          topupData.append(k, topupFormData[k])
        }
      }
      this.isLoading = true

      if (this.haveTopupData) {
        this.$http({
          url: topupUrl,
          method: topupMethod,
          data: topupData,
        })
      }

      const autoRechargeData = new FormData()

      autoRechargeData.append(
        'should_auto_refill_wallet_on_negative_balance',
        this.form.should_auto_refill_wallet_on_negative_balance
      )
      autoRechargeData.append(
        'auto_refill_amount',
        this.form.auto_refill_amount
      )
      autoRechargeData.append(
        'is_rider_allowed_to_toggle_auto_refill_setting',
        this.form.is_rider_allowed_to_toggle_auto_refill_setting
      )

      this.$http({
        url: useEndpoints.fleet.update(this.primaryKey),
        method: 'PATCH',
        data: autoRechargeData,
      })

      const othersFormData = new FormData()
      othersFormData.append(
        'is_free_ride_allowed',
        this.form.is_free_ride_allowed
      )
      othersFormData.append(
        'should_apply_extra_charge_for_non_local_rider',
        this.form.should_apply_extra_charge_for_non_local_rider
      )

      if (this.form.should_apply_extra_charge_for_non_local_rider) {
        othersFormData.append(
          'extra_charge_percentage_for_non_local_rider',
          this.form.extra_charge_percentage_for_non_local_rider
        )
      }

      this.$http({
        url: useEndpoints.fleet.update(this.primaryKey),
        method: 'PATCH',
        data: othersFormData,
      })

      if (this.form.useOrgPaymentGateway) {
        this.updatePaymentGatewayConfigFlag(this.form.useOrgPaymentGateway)
        return
      }

      const paymentReq = this.$http({
        url: paymentUrl,
        method: paymentMethod,
        data: paymentData,
      })
      const requests = [paymentReq]

      // submit

      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [paymentRes] = responses
            const data = {
              payment: paymentRes.data,
            }
            console.log(data)
            this.updatePaymentGatewayConfigFlag(this.form.useOrgPaymentGateway)
            // note raw responses are being sent
            // this.$emit('save', { step: 3, data })

            const message = `Fleet Payment Settings ${
              this.isEditing ? 'updated' : 'added'
            } successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
    },
    async updatePaymentGatewayConfigFlag(status) {
      const configData = new FormData()
      configData.append('use_org_payment_gateway_config', status)
      await this.$http
        .patch(useEndpoints.fleet.update(this.primaryKey), configData)
        .then((res) => {
          console.log('step-3-fleet-update', res)
          this.$emit('save', { step: 3 })
        })
        .catch((err) => {
          console.log('step-3-fleet-update-error', { err })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.section-name {
  @apply text-sm font-bold text-gray-500;
}
</style>
